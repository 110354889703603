




























import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ServiceUnavailablePage extends Vue {

  get lastPath() {
    return this.$route.query['lastPath'];
  }

}
