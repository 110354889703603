









































import {Component, Vue} from 'vue-property-decorator';
import {clearStorage} from '@/store/index.store';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import BMessage from 'buefy/src/components/message/Message.vue';
import {Context} from '@/model/site/context.model';
import {Getter} from 'vuex-class';
import {CONTEXT} from '@/store/context.store';

const mailtoLink = require('mailto-link');

@Component({
  components: {BMessage},

  metaInfo: {
    title: '404 | Die Seite wurde nicht gefunden',
  },
})
export default class ErrorPage extends Vue {

  @Getter(CONTEXT) context!: Context;

  created() {
    // TODO re-direct if hf-backend is reachable again
    clearStorage();
    const errorMsg = `Page not found: '${this.currentPath}'`;
    Vue.$log.error(errorMsg);
    Sentry.captureMessage(errorMsg, Severity.Error);
  }

  get mailTo() {
    return mailtoLink({
      to: this.errorMailRecipient,
      subject: this.errorMailSubject,
      body: this.errorMailBody,
    });
  }

  get errorMailRecipient() {
    return this.context.configuration.site.errorMail.recipient;
  }

  get errorMailSubject() {
    return this.context.configuration.site.errorMail.subject;
  }

  get errorMailBody() {
    let body = this.context.configuration.site.errorMail.body;
    body = body.replace('{0}', this.currentPath);
    return body;
  }

  get currentPath() {
    let currentPath: any = this.$route.query['path'];
    if (!currentPath) {
      currentPath = this.$router.currentRoute.path;
    }
    return currentPath;
  }

  get message() {
    return this.$route.query['message'];
  }

  get messages(): string[] {
    if (!this.message) {
      return [];
    }
    if (Array.isArray(this.message)) {
      return this.message;
    } else {
      return [this.message];
    }
  }

}
